import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { AppService, StatusBarService } from 'pw-lib';
import { slideAnimation } from './core/animation';
import { AppUpdateService } from './core/services/app-update.service';
import { LanguageService } from './core/services/language.service';
import { RouteHelperService } from './core/services/route-helper.service';
import { UiService } from './core/services/ui.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideAnimation],
})
export class AppComponent implements OnInit {
  /**
   * appViewport 라우팅 방향 저장용, `RouteHelperService.isGoingBack`은 타이밍이 안맞아 사용 불가
   */
  private _isGoingBack = false;

  constructor(
    public routeHelperService: RouteHelperService,
    public uiService: UiService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _swUpdate: SwUpdate,
    private _appService: AppService,
    private _statusBarService: StatusBarService,
    private _translateService: TranslateService,
    private languageService: LanguageService,
    private appUpdateService: AppUpdateService
  ) {}

  ngOnInit(): void {
    this._activatedRoute.queryParamMap.subscribe((paramMap) => {
      if (paramMap.get('lang')) {
        this.changeLang(paramMap.get('lang'));
      }
    });
    this.changeLang('ko');

    this.appUpdateService.init();
    // this.updateMaterialThemeColor();
    this.updateAppStyle();
  }

  /**
   * 앱 화면 전체를 사용할 수 있도록 설정하고, 상태바 아이콘을 밝게 설정한다
   */
  updateAppStyle(): void {
    this._statusBarService.setStatusBarBrightness('dark');
    // 기본 뒤로가기 처리 비활성화
    this._appService.useDefaultBackHandler(false);
    // 루트라면 앱 종료, 아니면 뒤로가기
    this._appService.addBackPressed(() => {
      if (
        this.routeHelperService.getCurrentActivatedRoute().snapshot.data
          .type === 'root'
      ) {
        if (!this._router.url.includes('home')) {
          this._router.navigate(['home']);
          return;
        }
        this._appService.exit();
      } else {
        if (
          this.routeHelperService.getCurrentActivatedRoute().snapshot.data
            .handleBack
        ) {
          return;
        }
        this.routeHelperService.goBack();
      }
    });
  }

  onActivate(): void {
    this._isGoingBack = false;
  }

  onDeactivate(): void {
    this._isGoingBack = true;
  }

  /**
   * 라우팅 애니메이션 시작시 동작
   * @param appViewport main 엘리먼트, 앱 화면
   */
  onRouteAnimationsStart(appViewport: HTMLElement): void {
    // 후방향 라우팅시 스크롤 재설정
    if (this._isGoingBack) {
      // TODO: cwi: 2021-01-18: overflow: auto에 의해 이벤트 발생자 변경되는경우 복원 불가능, 라우팅 히스토리 방식 자체를 개선해야됨. 아이오닉 벤치마킹 필요.
      appViewport.scrollTo(this.routeHelperService.scrollTo);
    }
  }

  /**
   * 라우팅 애니메이션 종료시 동작
   * @param appViewport main 엘리먼트, 앱 화면
   */
  onRouteAnimationsDone(appViewport: HTMLElement): void {
    // routeDirection 초기화
    this.routeHelperService.routeDirection$.next('');

    // 전방향 라우팅시 스크롤 초기화
    if (!this._isGoingBack) {
      appViewport.scrollTo(0, 0);
    }
  }

  /**
   * 기본 표시 언어 설정 변경
   */
  changeLang(lang: string): void {
    this._translateService.setDefaultLang(lang);
    this.languageService.changeLang(lang);
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/main-layout/main-layout.module').then(
        (m) => m.MainLayoutModule
      ),
    canActivate: [AuthGuard],
  },
  // 뒤로가기
  { path: 'back', canActivate: [], redirectTo: '' },
  // 상태 유지하며 홈으로 이동
  { path: 'root', canActivate: [], redirectTo: '' },
  // 로그인
  {
    path: 'login',
    canActivate: [],
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
  },
  // 로그아웃
  {
    path: 'logout',
    canActivate: [],
    loadChildren: () =>
      import('./pages/logout/logout.module').then((m) => m.LogoutModule),
  },
  { path: '**', redirectTo: '' },
  {
    path: 'not-found',
    loadChildren: () =>
      import('./pages/not-found/not-found.module').then(
        (m) => m.NotFoundModule
      ),
  },
  { path: '**', redirectTo: 'not-found' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
      initialNavigation: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

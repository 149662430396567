import { registerLocaleData } from '@angular/common';
import {
  HttpClient,
  HttpClientJsonpModule,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import localeKo from '@angular/common/locales/ko';
import { LOCALE_ID, NgModule } from '@angular/core';
import { COMPOSITION_BUFFER_MODE } from '@angular/forms';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  NativeDateAdapter,
} from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  MatFormFieldDefaultOptions,
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
} from '@angular/material/form-field';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxMaskModule } from 'ngx-mask';
import {
  AuthInterceptor,
  PwEditorOption,
  PW_EDITOR_OPTION,
  PW_STORAGE_PREFIX,
  PW_SUPPORTED_LOCALES,
} from 'pw-lib';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './auth/auth.service';
import { DialogModule } from './components/dialog/dialog.module';
import { LayoutModule } from './components/layout/layout.module';
import { maskConfig } from './core/mask-config';
import {
  PwUpdateOption,
  PW_UPDATE_OPTION,
} from './core/services/app-update.service';
import { HomeComponent } from './pages/home/home.component';
import { PipesModule } from './shared/pipes/pipes.module';
import { PwMissingTranslationHandler } from './shared/services/pw-missing-translation-handler.service';
import { SharedModule } from './shared/shared.module';

registerLocaleData(localeKo);
const locale = 'ko-KR';

const createTranslateLoader = (http: HttpClient) => {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

const matFormFieldDefaultOptions: MatFormFieldDefaultOptions = {
  appearance: 'outline',
  floatLabel: 'always',
};

const pwUpdateOption: PwUpdateOption = {
  messageTranslateKey: 'askUpdate',
  actionTranslateKey: 'update',
};

const pwEditorOption: PwEditorOption = {
  defaultFileServerUrl: environment.fileServerUrl,
};

@NgModule({
  declarations: [AppComponent, HomeComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
    }),
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    DialogModule,
    PipesModule,
    SharedModule,
    // NgxEchartsModule.forRoot({
    //   echarts: () => import('echarts'),
    // }),
    NgxMaskModule.forRoot(maskConfig),
    TranslateModule.forRoot({
      defaultLanguage: 'ko',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      // 선택한 언어의 번역 값이 적절하게 없을 때.
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: PwMissingTranslationHandler,
      },
    }),
    LayoutModule,
    MatSnackBarModule,
  ],
  providers: [
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useFactory: () => {
    //     return new LoggingInterceptor(environment.production);
    //   },
    //   multi: true,
    // },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useFactory: (authService: AuthService) => {
        return new AuthInterceptor(
          authService,
          `${environment.apiServerUrl}/api`
        );
      },
      deps: [AuthService],
    },

    { provide: COMPOSITION_BUFFER_MODE, useValue: false },
    { provide: LOCALE_ID, useValue: locale },
    { provide: MAT_DATE_LOCALE, useValue: locale },
    {
      provide: DateAdapter,
      useClass: NativeDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: PW_STORAGE_PREFIX, useValue: 'kumahanin' },
    {
      provide: MatDialogRef,
      useValue: {},
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: matFormFieldDefaultOptions,
    },
    {
      provide: PW_SUPPORTED_LOCALES,
      useValue: [
        { name: '한국어', value: 'ko' },
        { name: '日本語', value: 'ja' },
        // { name: 'English', value: 'en' },
      ],
    },
    { provide: PW_UPDATE_OPTION, useValue: pwUpdateOption },
    { provide: PW_EDITOR_OPTION, useValue: pwEditorOption },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

/* eslint-disable camelcase */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractAuthService, LoginInfo, StorageService } from 'pw-lib';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

/**
 * 인증(토큰) 정보 취급 서비스
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService extends AbstractAuthService {
  constructor(
    protected httpClient: HttpClient,
    protected storageService: StorageService,
    private router: Router
  ) {
    super(
      httpClient,
      storageService,
      `${environment.apiServerUrl}/oauth/token`,
      'admin',
      'kumahanin:paywith1234'
    );
  }

  /**
   * 로그인 정보 취득v
   */
  getLoginInfo$(id?: string): Observable<LoginInfo> {
    if (this.loginInfo) {
      // 클래스 내의 loginInfo 값이 있으면, info 호출
      return of(this.loginInfo);
    }

    // 인증정보 획득
    return this.getNewAuth().pipe(
      mergeMap(() => {
        // 로그인 정보 획득
        return this.httpClient.get(
          `${environment.apiServerUrl}/api/admin/adminId/${
            id ?? super.userName ?? this.loginInfo.id
          }`
        );
      }),
      map((loginInfo: LoginInfo) => {
        if (!loginInfo) {
          throw new Error('Check ID or Password please.');
        }

        this.loginInfo = { ...loginInfo, adminPw: undefined };

        // 로그인 정보 반환
        return this.loginInfo;
      })
    );
  }

  /**
   * 로그아웃
   */
  logout(): void {
    this.clearAuth();
    this.router.navigateByUrl('/login');
  }
}
